import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormCheck,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cilLockLocked,
  cilUser,
  cilBirthdayCake,
  cilInfo,
  cilCheck,
  cilAddressBook,
} from '@coreui/icons'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import CusResultModal from 'src/viewsCommon/CusResultModal'
import CusProgressModal from 'src/viewsCommon/CusProgressModal'
import CenterRuleDetailViewModal from 'src/viewsCus/centerRule/CenterRuleDetailViewModal'
import RegisterResultModal from './RegisterResultModal_passit'

const Register_passit = (param) => {
  const [isResultOpen, setIsResultOpen] = useState(false)
  const [inputType, setInputType] = useState('회원가입')
  const [resultTitle, setResultTitle] = useState('')
  const [resultBody, setResultBody] = useState('')

  const openResultModal = () => {
    // 입력 팝업 open
    setIsResultOpen(true)
  }

  const closeResultModal = () => {
    if (resultTitle == '성공') {
      // 로그인화면으로 이동
      navigate('/login')
    }
    // 입력 팝업 close
    setIsResultOpen(false)
  }

  const [isProgressOpen, setIsProgressOpen] = useState(false)

  const openCusProgressModal = () => {
    setIsProgressOpen(true)
  }

  const closeProgressModal = () => {
    setIsProgressOpen(false)
  }

  const [visible, setVisible] = useState(false)
  const [headerDesc, setHeaderDesc] = useState('화면설명')
  const [data, setData] = useState({})

  // 전체 동의 체크박스
  const [selectAll, setSelectAll] = useState(false)
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  })

  const handleSelectAllChange = (event) => {
    // console.log('handleSelectAllChange', event.target.checked)
    const checked = event.target.checked
    setSelectAll(checked)
    const updatedCheckboxes = { ...checkboxes }
    for (const key in updatedCheckboxes) {
      updatedCheckboxes[key] = checked
    }
    setCheckboxes(updatedCheckboxes)
  }

  const handleCheckboxChange = (event) => {
    // console.log('handleCheckboxChange', event.target)
    const { name, checked } = event.target
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    })

    // 현재 체크박스를 제외한 나머지 체크박스의 상태를 확인하여 전체 동의 상태 업데이트
    const otherCheckboxes = Object.keys(checkboxes).filter((key) => key !== name)
    const allOtherChecked = otherCheckboxes.every((key) => checkboxes[key])
    setSelectAll(allOtherChecked && checked)
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  // 최초실행 (빈 배열을 전달하여 한 번만 실행)
  useEffect(() => {
    const urlParamUcuid = queryParams.get('ucuid')
    const urlParamMobileNo = queryParams.get('mno')
    const urlParamName = queryParams.get('name')
    const urlParamBirthday = queryParams.get('bd')
    if (urlParamUcuid) {
      refs.ucuid.current.value = urlParamUcuid
      refs.ucuidText.current.value = '본인인증 완료'
      refs.userid.current.value = urlParamMobileNo
      refs.name.current.value = urlParamName
      refs.birthday.current.value = urlParamBirthday
    } else {
      refs.ucuidText.current.value = '본인인증 미완료 *'
    }
  }, [])

  // RegisterResultModal 컴포넌트
  const registerResultModalRef = useRef()

  // 입력
  const navigate = useNavigate()

  const refs = {
    ucuid: useRef(null),
    ucuidText: useRef(null),
    userid: useRef(null),
    password: useRef(null),
    passwordRe: useRef(null),
    name: useRef(null),
    birthday: useRef(null),
  }

  const checkData = () => {
    var saveDataCheckResult = ''
    if (refs.ucuid.current.value === '') {
      saveDataCheckResult = '본인인증이 완료되지 않았습니다.'
    } else if (refs.password.current.value === '') {
      saveDataCheckResult = '비밀번호를 입력해 주세요.'
    } else if (refs.passwordRe.current.value === '') {
      saveDataCheckResult = '비밀번호 확인을 입력해 주세요.'
    } else if (refs.password.current.value !== refs.passwordRe.current.value) {
      saveDataCheckResult = '입력한 비밀번호가 일치하지 않습니다.'
    } else if (checkboxes.checkbox1 === false || checkboxes.checkbox2 === false) {
      saveDataCheckResult = '필수약관에 동의해 주세요.'
    }

    return saveDataCheckResult
  }

  const saveData = (attachFileUid_01, attachFileUid_02) => {
    // console.log('refs.title: ', refs.title.current.value)
    const attachFileRequestList = []
    if (attachFileUid_01) {
      const attachFileRequest_01 = {
        attachFileUid: attachFileUid_01,
        // targetType: '01',
      }
      attachFileRequestList.push(attachFileRequest_01)
    }
    if (attachFileUid_02) {
      const attachFileRequest_02 = {
        attachFileUid: attachFileUid_02,
        // targetType: '02',
      }
      attachFileRequestList.push(attachFileRequest_02)
    }

    setData((prevState) => ({
      ...prevState,
      ucuid: refs.ucuid.current.value,
      ucuidText: refs.ucuidText.current.value,
      userid: refs.userid.current.value,
      password: refs.password.current.value,
      passwordRe: refs.passwordRe.current.value,
      name: refs.name.current.value,
      birthday: refs.birthday.current.value,
    }))

    // 입력값 체크
    var saveDataCheckResult = checkData()
    if (saveDataCheckResult !== '') {
      setResultTitle('확인')
      setResultBody(saveDataCheckResult)
      openResultModal()
      return false
    }

    const userDetailsRequest = {
      userCheckUid: refs.ucuid.current.value,
      // name: refs.name.current.value,
      // birthday: modBirthday,
    }

    const saveDataParam = {
      userid: refs.userid.current.value,
      password: refs.password.current.value,
      userDetailsRequest: userDetailsRequest,
      attachFileRequestList: attachFileRequestList,
    }
    console.log('saveDataParam: ', saveDataParam)

    cusUtil
      .axiosPostOrPut(
        '/cube_dev_local/api/guest/user/v02/user/withoutAuthNum',
        saveDataParam,
        param.uid,
      )
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          // setResultTitle('성공')
          // setResultBody(inputType + '에 성공하였습니다.')
          // openResultModal()
          if (registerResultModalRef.current) {
            registerResultModalRef.current.childFetchData(response.data.detail)
          }
        } else {
          setResultTitle('에러')
          setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          openResultModal()
        }
      })
      .catch((error) => {
        console.error('register fail: ', error)
        if (error.response && error.response.data && error.response.data.resultYne) {
          const responseData = error.response.data
          setResultTitle('에러')
          if (responseData.resultCode) {
            let resultMsg = ''
            if (responseData.resultCode == '1001') {
              resultMsg = '이미 가입된 휴대폰번호(아이디) 입니다.'
            }
            setResultBody('[' + responseData.resultCode + '] ' + resultMsg)
          } else {
            setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          }
          openResultModal()
        } else {
          setResultTitle('예외사항 발생')
          setResultBody('[' + error.code + '] ' + error.message)
          openResultModal()
        }
      })
  }

  const handleCancel = () => {
    navigate('/login')
  }

  const handleCheckplus = () => {
    const closeButtonYn = encodeURIComponent('y')
    const closeButtonUrl = encodeURIComponent(
      window.location.protocol + '//' + window.location.host + '/login#/register',
    )
    const closeButtonFun = encodeURIComponent('')
    // console.log('closeButtonUrl :', window.location.protocol)
    // console.log('closeButtonUrl :', window.location.host)
    // console.log('closeButtonUrl :', closeButtonUrl)

    const checkplus = `${process.env.REACT_APP_API_URL}/checkplus/mainUser?cid=&did=&cb=${closeButtonYn}&cbu=${closeButtonUrl}&cbf=${closeButtonFun}`
    window.location.href = checkplus
  }

  // 파일업로드
  const fileInputRef_01 = useRef(null)
  const fileInputRef_02 = useRef(null)
  const [files, setFiles] = useState({
    selectedFile_01: null,
    selectedFile_02: null,
  })
  const [imageUrl_01, setImageUrl_01] = useState()
  const [imageUrl_02, setImageUrl_02] = useState()

  const handleFileClick_01 = () => {
    fileInputRef_01.current.click()
  }
  const handleFileClick_02 = () => {
    fileInputRef_02.current.click()
  }
  const handleFileChange_01 = (event) => {
    const file = event.target.files[0]
    setFiles((prevFiles) => ({
      ...prevFiles,
      selectedFile_01: file,
    }))
    const reader = new FileReader()

    reader.onload = () => {
      setImageUrl_01(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const handleFileChange_02 = (event) => {
    const file = event.target.files[0]
    setFiles((prevFiles) => ({
      ...prevFiles,
      selectedFile_02: file,
    }))
    const reader = new FileReader()

    reader.onload = () => {
      setImageUrl_02(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const uploadData = async ({ event }) => {
    // 입력값 체크
    var saveDataCheckResult = checkData()
    if (saveDataCheckResult !== '') {
      setResultTitle('확인')
      setResultBody(saveDataCheckResult)
      openResultModal()
      return false
    }

    // 진행중
    openCusProgressModal()

    // 파일 숫자 카운트
    let fileCnt = 0
    let uploadFileCnt = 0
    Object.entries(files).forEach(([fileKey, fileValue]) => {
      if (fileValue) {
        fileCnt++
      }
    })

    // 파일 없는경우
    if (fileCnt == 0) {
      // 입력값 전달
      closeProgressModal()
      saveData()
      return
    }

    // 파일 있는경우 파일업로드
    let attachFileUid_01 = ''
    let attachFileUid_02 = ''
    Object.entries(files).forEach(([fileKey, fileValue]) => {
      // console.log('Key:', fileKey, 'Value:', fileValue)

      if (fileValue) {
        const url = '/cube_dev_local/api/file/guest/upload'
        console.log('upload url: ', url)

        const formData = new FormData()
        formData.append('file', fileValue)

        cusUtil
          .axiosUpload(url, formData)
          .then((response) => {
            closeProgressModal()
            console.log(response)
            const responseData = response.data

            if (responseData.resultYne == 'Y') {
              setResultTitle('확인')
              setResultBody('정상적으로 등록 되었습니다.')
              // openResultModal()

              // console.log('result:', fileKey, responseData.resultMsg)
              if (fileKey == 'selectedFile_01') {
                attachFileUid_01 = responseData.resultMsg
              } else if (fileKey == 'selectedFile_02') {
                attachFileUid_02 = responseData.resultMsg
              }

              uploadFileCnt++

              // 입력값 전달 (await Promise.all(apiCalls) 잘 동작안해서 이방식으로 처리)
              if (fileCnt == uploadFileCnt) {
                saveData(attachFileUid_01, attachFileUid_02)
              }
            } else {
              setResultTitle('에러')
              setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
              openResultModal()
            }
          })
          .catch((error) => {
            closeProgressModal()
            console.error('file upload fail: ', error)
            setResultTitle('예외사항 발생')
            setResultBody('[' + error.code + '] ' + error.message)
            openResultModal()
          })
      }
    })
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-0">
              <CCardBody className="p-4">
                <CForm>
                  <h2>Register</h2>
                  <p className="text-medium-emphasis">
                    본인인증후 기타 정보를 입력해 주세요. (* 필수정보)
                  </p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilCheck} />
                    </CInputGroupText>
                    <CFormInput placeholder="" size="sm" ref={refs.ucuidText} disabled />
                    <CFormInput type="hidden" ref={refs.ucuid} />
                    <CButton color="primary" size="sm" onClick={() => handleCheckplus()}>
                      본인인증 하기
                    </CButton>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput placeholder="휴대폰번호 *" ref={refs.userid} size="sm" disabled />
                  </CInputGroup>
                  {/* <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput placeholder="Email" autoComplete="email" />
                  </CInputGroup> */}
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      size="sm"
                      placeholder="비밀번호 * (숫자 6자리)"
                      ref={refs.password}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      size="sm"
                      placeholder="비밀번호 확인 * (숫자 6자리)"
                      ref={refs.passwordRe}
                    />
                  </CInputGroup>
                  <CInputGroup
                    className="mb-3"
                    style={{
                      display: 'none',
                    }}
                  >
                    <CInputGroupText>
                      <CIcon icon={cilInfo} />
                    </CInputGroupText>
                    <CFormInput placeholder="사용자이름" ref={refs.name} size="sm" disabled />
                  </CInputGroup>
                  <CInputGroup
                    className="mb-3"
                    style={{
                      display: 'none',
                    }}
                  >
                    <CInputGroupText>
                      <CIcon icon={cilBirthdayCake} />
                    </CInputGroupText>
                    <CFormInput placeholder="생년월일" ref={refs.birthday} size="sm" disabled />
                  </CInputGroup>

                  <CInputGroup className="mb-4">
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        border: '1px solid gray',
                      }}
                    >
                      {imageUrl_01 && (
                        <img
                          src={imageUrl_01}
                          alt=""
                          style={{
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      )}
                    </div>
                    <CButton color="light" size="sm" onClick={handleFileClick_01} className="ms-2">
                      사진추가
                    </CButton>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleFileChange_01}
                      ref={fileInputRef_01}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        border: '1px solid gray',
                      }}
                    >
                      {imageUrl_02 && (
                        <img
                          src={imageUrl_02}
                          alt=""
                          style={{
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      )}
                    </div>
                    <CButton color="light" size="sm" onClick={handleFileClick_02} className="ms-2">
                      사진추가
                    </CButton>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleFileChange_02}
                      ref={fileInputRef_02}
                    />
                  </CInputGroup>

                  <CInputGroup className="mb-4">
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <CRow>
                        <CCol
                          xs={8}
                          className="text-medium-emphasis small"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <CFormCheck
                            id="ruleAll"
                            name="checkboxAll"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            label="전체 동의"
                            className=" pt-1"
                          />
                        </CCol>
                        <CCol
                          xs={4}
                          className="text-right"
                          style={{
                            textAlign: 'right',
                          }}
                        ></CCol>
                      </CRow>
                    </div>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <CRow>
                        <CCol xs={8} className="text-medium-emphasis small">
                          <CFormCheck
                            id="ruleService"
                            name="checkbox1"
                            checked={checkboxes.checkbox1}
                            onChange={handleCheckboxChange}
                            label="서비스 이용약관 동의 (필수)"
                            className=" pt-1"
                          />
                        </CCol>
                        <CCol
                          xs={4}
                          className="text-right"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <CenterRuleDetailViewModal ruleCd="01" ruleText="약관보기" isButton="y" />
                        </CCol>
                      </CRow>
                    </div>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <CRow>
                        <CCol xs={8} className="text-medium-emphasis small">
                          <CFormCheck
                            id="rulePrivacy"
                            name="checkbox2"
                            checked={checkboxes.checkbox2}
                            onChange={handleCheckboxChange}
                            label="개인정보수집 및 이용약관 동의 (필수)"
                            className=" pt-1"
                          />
                        </CCol>
                        <CCol
                          xs={4}
                          className="text-right"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <CenterRuleDetailViewModal ruleCd="02" ruleText="약관보기" isButton="y" />
                        </CCol>
                      </CRow>
                    </div>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <CRow>
                        <CCol xs={8} className="text-medium-emphasis small">
                          <CFormCheck
                            id="ruleAd"
                            name="checkbox3"
                            checked={checkboxes.checkbox3}
                            onChange={handleCheckboxChange}
                            label="마케팅 정보수신 동의 (선택)"
                            className=" pt-1"
                          />
                        </CCol>
                        <CCol
                          xs={4}
                          className="text-right"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <CenterRuleDetailViewModal ruleCd="04" ruleText="약관보기" isButton="y" />
                        </CCol>
                      </CRow>
                    </div>
                  </CInputGroup>
                  <RegisterResultModal
                    title="가입결과"
                    ref={registerResultModalRef}
                  ></RegisterResultModal>

                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="secondary"
                          style={{
                            width: '100%',
                          }}
                          onClick={() => handleCancel()}
                        >
                          취소
                        </CButton>
                      </CCol>
                      <CCol xs={6}>
                        <CButton
                          color="success"
                          style={{
                            width: '100%',
                          }}
                          onClick={uploadData}
                        >
                          회원가입
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      <CusResultModal
        isOpen={isResultOpen}
        closeModal={closeResultModal}
        resultTitle={resultTitle}
        resultBody={resultBody}
      />
      <CusProgressModal isOpen={isProgressOpen} closeModal={closeProgressModal} />
    </div>
  )
}

export default Register_passit
