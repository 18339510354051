import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNavLink,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CCallout,
  CImage,
  CLoadingButton,
} from '@coreui/react-pro'
import QRCode from 'qrcode.react'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUtil_passit from 'src/viewsCus/_company/common/CusUtil_passit'
import CusResultModal from 'src/viewsCommon/CusResultModal'
import UserDetailInfo from './sub/UserDetailInfo_passit'

const RegisterResultModal_passit = forwardRef((param, ref) => {
  useImperativeHandle(ref, () => ({
    childFetchData: (detail) => {
      fetchData(detail)
    },
  }))

  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({})
  const [dataDetail, setDataDetail] = useState({})

  const fetchData = async (detail) => {
    // const url = '/cube_dev_local/api/admin/center/centerDoor/' + param.uid
    // console.log('detail url: ', url)
    // cusUtil
    //   .axiosGet(url)
    //   .then((response) => {
    //     console.log(response)
    //     setData(response.data)
    //     setVisible(!visible)
    //   })
    //   .catch((error) => {
    //     console.error('centerDoor detail fail: ', error)
    //   })
    setVisible(!visible)
    setDataDetail(detail)
  }

  const handleLogin = async () => {
    navigate('/login')
  }

  return (
    <>
      {/* <CNavLink role="button" onClick={() => fetchData()}>
        {param.title}
      </CNavLink> */}
      <CModal fullscreen backdrop="static" visible={visible} onClose={() => setVisible(false)}>
        {/* <CModalHeader>
          <CModalTitle>상세정보</CModalTitle>
        </CModalHeader> */}
        {/* <CModalBody> */}
        <div style={cusUtil_passit.styleDivValign}>
          <div style={cusUtil_passit.styleDivValignTop}>
            <CRow>
              <CCol>
                <CImage fluid src="/images/pas/ic_logo_white.png" className="pt-5" />
              </CCol>
            </CRow>
          </div>

          <div style={cusUtil_passit.styleDivValignMiddle}>
            <CRow className="pt-20">
              <CCol>
                <UserDetailInfo detail={dataDetail} name={dataDetail.name}></UserDetailInfo>
              </CCol>
            </CRow>
          </div>

          <div style={cusUtil_passit.styleDivValignBottom2}>
            <CRow>
              <CCol>
                <CRow>
                  <CCol className="d-grid mt-3 my-3">
                    <CLoadingButton
                      // loading={btnLoignState}
                      // size="sm"
                      color="danger"
                      className="text-white border-radius-0"
                      onClick={handleLogin}
                      // onClick={() => setVisible(false)}
                      style={cusUtil_passit.styleBtnRed}
                    >
                      로그인 하기
                    </CLoadingButton>
                  </CCol>
                </CRow>
                <div className="mb-4" style={cusUtil_passit.styleFontNormalSmall5}>
                  Copyright Tobesmart., Ltd. All right reserved.
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
        {/* </CModalBody> */}
      </CModal>
    </>
  )
})

export default RegisterResultModal_passit
