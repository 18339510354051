export const styleBg = {
  'background-color': '#5a5a5a',
}
export const styleFontBold = {
  'font-weight': '500',
}
export const styleFontBoldRed = {
  ...styleFontBold,
  color: '#f30041',
}
export const styleFontNormal5 = {
  'font-weight': '100',
  color: 'white',
  opacity: '0.5',
  'text-decoration': 'none',
  '--cui-form-check-input-checked-bg-color': 'black',
}
export const styleFontNormal = {
  'font-weight': '100',
  color: 'white',
  opacity: '1',
  'text-decoration': 'none',
}
export const styleFontNormalSmall5 = {
  ...styleFontNormal5,
  'font-size': '13px',
}
export const styleFontNormalLarge5 = {
  ...styleFontNormal5,
  'font-size': '20px',
}
export const styleFontNormalXLarge5 = {
  ...styleFontNormal5,
  'font-size': '24px',
}
export const styleFontNormalSmall = {
  ...styleFontNormal,
  'font-size': '13px',
}
export const styleFontNormalLarge = {
  ...styleFontNormal,
  'font-size': '20px',
}
export const styleFontNormalXLarge = {
  ...styleFontNormal,
  'font-size': '24px',
}
export const styleDivValign = {
  ...styleBg,
  height: '100vh',
  display: 'flex',
  'flex-direction': 'column',
  margin: '0',
  padding: '0',
  'background-color': '#5a5a5a',
}
export const styleDivValignTop = {
  flex: '0 0 130px',
  padding: '30px',
  paddingTop: '0',
  paddingBottom: '0',
}
export const styleDivValignBottom = {
  flex: '0 0 130px',
  padding: '30px',
  paddingTop: '0',
  paddingBottom: '0',

  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'flex-end',
}
export const styleDivValignMiddle = {
  flex: '1',
  padding: '30px',
  paddingTop: '0',
  paddingBottom: '0',
}
export const styleDivValignBottom2 = {
  flex: '0 0 200px',
  padding: '30px',
  paddingTop: '0',
  paddingBottom: '0',

  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'flex-end',
}
export const styleInput = {
  ...styleBg,
  '--cui-input-focus-bg': '#5a5a5a',
  '--cui-input-placeholder-color': 'white',
  'font-size': '20px',
  color: 'white',
}
export const styleBtnRed = {
  '--cui-btn-color': '#f30041',
  '--cui-btn-bg': '#f30041',
  '--cui-btn-border-color': '#f30041',
  '--cui-btn-hover-color': '#f30041',
  '--cui-btn-hover-bg': '#f30041',
  '--cui-btn-hover-border-color': '#f30041',
  'font-size': '20px',
}
export const styleBtnGray = {
  '--cui-btn-color': '#696969',
  '--cui-btn-bg': '#696969',
  '--cui-btn-border-color': '#696969',
  '--cui-btn-hover-color': '#696969',
  '--cui-btn-hover-bg': '#696969',
  '--cui-btn-hover-border-color': '#696969',
  'font-size': '20px',
}
