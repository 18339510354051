import React, { useEffect, useState, createRef } from 'react'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNavLink,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CCallout,
  CImage,
  CLoadingButton,
} from '@coreui/react-pro'
import QRCode from 'qrcode.react'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUtil_passit from 'src/viewsCus/_company/common/CusUtil_passit'
import CusResultModal from 'src/viewsCommon/CusResultModal'

const UserDetailInfo_passit = (param) => {
  const [data, setData] = useState({})
  console.log('UserDetailInfo_passit', param, param.name)

  return (
    <>
      <CRow>
        {(() => {
          if (param.detail && param.detail.ageType == 'adult') {
            return (
              <CCol className="pt-8" style={cusUtil_passit.styleFontNormalXLarge}>
                <span style={cusUtil_passit.styleFontBold}>{param.name}</span> 님은{' '}
                <span style={cusUtil_passit.styleFontBoldRed}>성인등급</span> 으로
                <div>파스서비스에 가입 되셨습니다.</div>
                <div>&nbsp;</div>
                <div>앞으로</div>
                <div>파스서비스 가맹 PC 방에</div>
                <span style={cusUtil_passit.styleFontBoldRed}>시간제한 없이</span>
                <div>입장이 가능합니다.</div>
              </CCol>
            )
          } else {
            return (
              <CCol className="pt-8" style={cusUtil_passit.styleFontNormalXLarge}>
                <span style={cusUtil_passit.styleFontBold}>{param.name}</span> 님은{' '}
                <span style={cusUtil_passit.styleFontBoldRed}>미성년등급</span> 으로
                <div>파스서비스에 가입 되셨습니다.</div>
                <div>&nbsp;</div>
                <div>고객님은</div>
                <div>파스서비스 가맹 PC 방에</div>
                <div>
                  <span style={cusUtil_passit.styleFontBoldRed}>심야시간</span> 에는{' '}
                </div>
                <span style={cusUtil_passit.styleFontBoldRed}>출입이 제한</span> 됩니다.
              </CCol>
            )
          }
        })()}
      </CRow>
    </>
  )
}

export default UserDetailInfo_passit
