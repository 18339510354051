import PropTypes from 'prop-types'
import React, { useEffect, useState, createRef } from 'react'
import classNames from 'classnames'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CPagination,
  CPaginationItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from '@coreui/react-pro'

const CusProgressModal = ({ isOpen, closeModal }) => {
  return (
    <>
      <CModal
        fullscreen
        // backdrop="static"
        backdrop={false}
        visible={isOpen}
        onClose={closeModal}
        style={{
          backgroundColor: 'rgba(244 244 244 / 5%)',
        }}
      >
        {/* <CModalHeader
          style={{
            backgroundColor: 'rgb(244 244 244)',
          }}
        >
          <CModalTitle></CModalTitle>
        </CModalHeader> */}
        <CModalBody>
          <div
            style={{
              // width: '100%',
              // textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
            alt=""
          >
            <CSpinner
              style={{ width: '100px', height: '100px', marginBottom: '10px' }}
              // color="secondary"
              color="info"
            />
            <div
              style={{
                // padding: '0',
                fontStyle: 'bold',
                color: 'black',
              }}
            >
              {/* 잠시만 기다려 주세요. */}
            </div>
          </div>
        </CModalBody>
      </CModal>
    </>
  )
}

CusProgressModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default CusProgressModal
