import PropTypes from 'prop-types'
import React, { useEffect, useState, createRef } from 'react'
import classNames from 'classnames'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CPagination,
  CPaginationItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'

const CusResultModal = ({ isOpen, closeModal, resultTitle, resultBody }) => {
  // console.log('LiveDemo:', isOpen, closeModal, resultTitle, resultBody)
  return (
    <>
      {/* <CButton onClick={() => setVisible(!visible)}>Launch Result modal</CButton> */}
      <CModal backdrop="static" visible={isOpen} onClose={closeModal}>
        <CModalHeader>
          <CModalTitle>{resultTitle}</CModalTitle>
        </CModalHeader>
        <CModalBody>{resultBody}</CModalBody>
        <CModalFooter>
          <CButton color="primary" size="sm" className="mx-1 text-white" onClick={closeModal}>
            닫기
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

CusResultModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  resultTitle: PropTypes.string,
  resultBody: PropTypes.string,
}

export default React.memo(CusResultModal)
